import { makeInitialState } from 'api/store/reducers/mixins/master.mixins';
import { order } from 'api';
import { SET_ORDERS, SET_LIMIT, SET_OFFSET, SET_PAGINATION } from '../actions/order.action';

const initialState = () => ({
  ...makeInitialState(),
  orders: [],
  limit: 10,
  offset: 0,
  pagination: {
    limit: 10,
    offset: 0,
    totalItem: 0,
  },
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case SET_ORDERS:
      return {
        ...state,
        orders: action.orders,
      };
    case SET_LIMIT:
      return {
        ...state,
        limit: action.limit,
      };
    case SET_OFFSET:
      return {
        ...state,
        offset: action.offset,
      };
    case SET_PAGINATION:
      return {
        ...state,
        pagination: {
          limit: Number(action.pagination.limit || 10),
          offset: Number(action.pagination.offset || 0),
          totalItem: Number(action.pagination.totalItem || 0),
        },
      };
    default:
      return order(state, action);
  }
};
