import React from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import './App.scss';
import { bindActionCreators } from 'redux';
import { authAction } from './store/actions';
import { getAxios } from './lib/apiClient';
import UserService from './lib/service/user';
import accessTokenStorage from 'api/storage/access-token/factory';
import Modal from './components/Modal';
import FormChangePassword from './components/Form/FormChangePassword';
import { Button } from 'reactstrap';
import { retrieveErrorMessage } from "api/helpers/common";

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login/Login'));
const Register = React.lazy(() => import('./views/Pages/Register/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500/Page500'));

const history = createBrowserHistory();

getAxios().interceptors.response.use(response => response, ({ response }) => {
  if (response.status === 401) {
    accessTokenStorage.removeAccessToken();
    history.push('/login');
  }
});

const PUBLIC_PATHS = ['/login', '/logout'];

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      fetchingMe: false,
      isModalOpened: false,
      modalTitle: '',
      modalBody: () => null,
      modalFooter: () => null,
      changingPassword: false,
      isValidChangePasswordForm: false,
      changePasswordPayload: {
        password: '',
        confirmPassword: '',
      },
    };
  }

  componentDidMount() {
    const { pathname } = history.location;
    const accessToken = getTokenFromStorage();
    const isPublic = PUBLIC_PATHS.includes(pathname);

    if (!isPublic && accessToken) {
      this.getMe(accessToken);
    } else {
      if (!isPublic) history.push('/login');
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const accessToken = getTokenFromStorage();
    if (accessToken && !this.props.auth.user.email) {
      this.getMe(accessToken);
    }
  }

  getMe(accessToken) {
    if (!this.state.fetchingMe) {
      this.setState({
        fetchingMe: true,
      }, () => {
        UserService.me(accessToken)
          .then(user => this.props.setUser(user))
          .catch(() => {
            this.props.clearToken();
            history.push('/login');
          })
          .finally(() => {
            this.setState({
              loading: false,
              fetchingMe: false,
            });
          });
      });
    }

  }

  setChangePasswordPayload(isValidChangePasswordForm, changePasswordPayload) {
    this.setState({ isValidChangePasswordForm, changePasswordPayload });
  }

  doChangePassword(payload) {
    this.setState({
      changingPassword: true,
    }, () => {
      UserService.changePassword(payload)
        .then(response => {
          const message = response && response.success
            ? 'Password changed!'
            : 'Failed to change password, please try again';

          this.setState({ message }, () => {
            this.closeModal();
          });
        })
        .catch(e => {
          this.setState({
            message: retrieveErrorMessage(e),
          });
        })
        .finally(() => {
          this.setState({
            changingPassword: false,
          });
        });
    });
  }

  onChangePassword(e) {
    e.preventDefault();

    this.showModal({
      modalTitle: 'Change Password',
      modalBody: changingPassword => (
        <FormChangePassword
          changingPassword={changingPassword}
          payload={(isValid, payload) => this.setChangePasswordPayload(isValid, payload)}
        />
      ),
      modalFooter: (isValid, payload, changingPassword) => (
        <React.Fragment>
          <Button
            color="primary"
            onClick={() => this.doChangePassword(payload)}
            disabled={!isValid || changingPassword}
          >
            {changingPassword ? 'Updating password...' : 'Change Password'}
          </Button>
        </React.Fragment>
      ),
    })
  }

  showModal(opts = {}) {
    this.setState({
      isModalOpened: true,
      modalTitle: opts.modalTitle || '',
      modalBody: opts.modalBody || null,
      modalFooter: opts.modalFooter || null,
    });
  }

  closeModal() {
    this.setState({
      isModalOpened: false,
      modalTitle: '',
      modalBody: () => null,
      modalFooter: () => null,
    });
  }

  toggleModal() {
    if (this.state.isModalOpened) this.closeModal();
  }

  logout(e) {
    e.preventDefault();
    this.props.clearToken();
    history.push('/login');
  }

  render() {
    if (this.state.loading) {
      return loading();
    }

    const {
      message,
      modalBody,
      modalFooter,
      isValidChangePasswordForm,
      changePasswordPayload,
      changingPassword,
    } = this.state;

    const body = modalBody(changingPassword);
    const footer = modalFooter(isValidChangePasswordForm, changePasswordPayload, changingPassword);

    return (
      <React.Fragment>
        <Modal
          title="Message"
          isOpen={!!message}
          toggle={() => this.setState({ message: '' })}
          body={message}
        />
        <Modal
          title={this.state.modalTitle}
          isOpen={this.state.isModalOpened}
          toggle={() => this.toggleModal()}
          body={body}
          footer={footer}
        />

        <Router history={history}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>}/>
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>}/>
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>}/>
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>}/>
              <Route path="/" name="Home" render={props => <DefaultLayout onChangePassword={e => this.onChangePassword(e)} logout={e => this.logout(e)} {...props}/>}/>
            </Switch>
          </React.Suspense>
        </Router>
      </React.Fragment>
    );
  }
};

const mapStateToProps = ({ auth }) => ({ auth });

const { clearToken, getTokenFromStorage, getMe, setToken, setUser } = authAction;

const mapDispatchToProps = dispatch => bindActionCreators({
  clearToken,
  getMe,
  setToken,
  setUser,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
