import {
  createReducer as apiCreateReducer,
  airportShuttleBooking,
  airportShuttleModify,
  area,
  district,
  paymentMethod,
  products,
  carRentalBooking,
} from 'api';
import auth from './auth.reducer';
import order from './order.reducer';

const reducers = {
  airportShuttleBooking,
  airportShuttleModify,
  area,
  district,
  order,
  paymentMethod,
  products,
  auth,
  carRentalBooking,
};

export const createReducer = (asyncReducers = {}) => apiCreateReducer({
  asyncReducers,
  reducers,
});

export default createReducer;
