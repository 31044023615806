import React from 'react';
import Formsy from 'formsy-react';
import FormInput from '../Input';

class FormChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isValid: false,
      payload: {
        oldPassword: '',
        password: '',
        confirmPassword: '',
      }
    };

    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onInvalid = this.onInvalid.bind(this);
    this.onValid = this.onValid.bind(this);
  }

  onValidSubmit() {};

  onInvalid() {
    this.setState({ isValid: false });
  };

  onValid() {
    this.setState({ isValid: true });
  };

  setPayload(key, value) {
    this.setState({
      payload: {
        ...this.state.payload,
        [key]: value,
      },
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isValid, payload } = this.state;
    if (isValid !== prevState.isValid || payload !== prevState.payload) {
      this.props.payload(isValid, payload);
    }
  }

  render() {
    return (
      <Formsy
        onValidSubmit={this.onValidSubmit}
        onInvalid={this.onInvalid}
        onValid={this.onValid}
      >
        <FormInput
          type="password"
          name="oldPassword"
          title="Old Password"
          disabled={this.props.changingPassword}
          value={this.state.payload.oldPassword}
          onChange={({ target }) => this.setPayload('oldPassword', target.value)}
          required
        />
        <FormInput
          type="password"
          name="password"
          title="New Password"
          disabled={this.props.changingPassword}
          value={this.state.payload.password}
          onChange={({ target }) => this.setPayload('password', target.value)}
          validations="minLength:8"
          validationError="Min length 8 characters"
          required
        />
        <FormInput
          type="password"
          name="confirmPassword"
          title="Confirm New Password"
          disabled={this.props.changingPassword}
          value={this.state.payload.confirmPassword}
          onChange={({ target }) => this.setPayload('confirmPassword', target.value)}
          validations="equalsField:password"
          validationError="Password did not match"
          required
        />
      </Formsy>
    );
  }

}

export default FormChangePassword;
