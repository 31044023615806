import { UserService } from 'api';
import accessTokenStorage from 'api/storage/access-token/factory';

export const SET_IS_AUTHENTICATING = '[AUTH] SET_IS_AUTHENTICATING';
export const SET_IS_INVALID_CREDENTIALS = '[AUTH] SET_IS_INVALID_CREDENTIALS';
export const SET_TOKEN = '[AUTH] SET_TOKEN';
export const CLEAR_TOKEN = '[AUTH] CLEAR_TOKEN';
export const SET_USER = '[AUTH] SET_USER';

export const authenticate = (email, password) => dispatch => {
  dispatch({ type: SET_IS_AUTHENTICATING, isAuthenticating: true });
  return new Promise(async (resolve, reject) => {
    try {
      const { token } = await UserService.login(email, password);
      dispatch({ type: SET_TOKEN, token });
      accessTokenStorage.setAccessToken(token);
      return resolve(token);
    } catch (e) {
      dispatch({ type: CLEAR_TOKEN });
      dispatch({ type: SET_IS_INVALID_CREDENTIALS, isInvalidCredentials: true });
      return reject(e);
    } finally {
      dispatch({ type: SET_IS_AUTHENTICATING, isAuthenticating: false });
    }
  });
};

export const getMe = token => dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      const user = await UserService.me(token);
      dispatch({ type: SET_USER, user });
      return resolve(user);
    } catch (e) {
      dispatch({ type: CLEAR_TOKEN });
      return reject(e);
    }
  });
};

export const setToken = token => dispatch => dispatch({ type: SET_TOKEN, token });
export const setUser = user => dispatch => dispatch({ type: SET_USER, user });
export const clearToken = () => dispatch => {
  accessTokenStorage.removeAccessToken();
  return dispatch({ type: CLEAR_TOKEN });
};

export const getTokenFromStorage = () => {
  return accessTokenStorage.getAccessToken();
};
