import apiClient from '../../lib/apiClient';
import { createInstance } from 'api/service/order/factory';
import { getFilter } from 'api/store/actions/mixins/master.mixins';
import { action } from 'api/store/actions/order.action';

const service = createInstance(apiClient);

export * from 'api/store/actions/order.action';

export const SET_ORDERS = '[ORDER] SET_ORDERS';
export const SET_LIMIT = '[ORDER] SET_LIMIT';
export const SET_OFFSET = '[ORDER] SET_OFFSET';
export const SET_PAGINATION = '[ORDER] SET_PAGINATION';

export const fetchOrder = params => dispatch => {
  const { SET_FETCHING, SET_ERROR, CLEAR_ERROR } = getFilter(action);

  dispatch({ type: SET_FETCHING, fetching: true });
  dispatch({ type: CLEAR_ERROR });

  return new Promise(async (resolve, reject) => {
    try {
      const opts = {
        limit: params.limit || 10,
        offset: params.offset || 0,
      };

      const { data, meta } = await service.getList(opts);

      const { pagination } = meta;

      dispatch({ type: SET_ORDERS, orders: Array.isArray(data) ? data : [] });
      dispatch({ type: SET_PAGINATION, pagination: pagination || opts });
      return resolve(data);
    } catch (e) {
      dispatch({ type: SET_ERROR, errorMessage: e.toString() });
      return reject(e);
    } finally {
      dispatch({ type: SET_FETCHING, fetching: false });
    }
  });
};
