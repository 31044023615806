import React from 'react';
import propTypes from 'prop-types';
import {
  Button,
  Modal as ReactStrapModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

class Modal extends React.Component {
  render() {
    const { isOpen, toggle, title, body, footer, customFooter } = this.props;

    return (
      <ReactStrapModal isOpen={isOpen} toggle={() => toggle()}>
        <ModalHeader>
          {title}
        </ModalHeader>
        <ModalBody>
          {body}
        </ModalBody>
        <ModalFooter>
          {footer}
          {!customFooter && (
            <Button
              color="secondary"
              onClick={() => toggle()}
            >
              Close
            </Button>
          )}
        </ModalFooter>
      </ReactStrapModal>
    );
  }
}

Modal.defaultProps = {
  title: 'Modal Title',
  customFooter: false,
};

Modal.propTypes = {
  title: propTypes.string,
  toggle: propTypes.func,
  body: propTypes.any,
  footer: propTypes.any,
  customFooter: propTypes.bool,
};

export default Modal;
