import apiClient from '../../lib/apiClient';
import { createInstance } from 'api/service/carRental/factory';
import {
  SET_BOOKING_DATA,
  SET_BOOKING_ERROR_MESSAGE,
  SET_IS_BOOKING,
  SET_STEP,
} from 'api/store/actions/carRental.action/booking';

export * from 'api/store/actions/carRental.action';

const service = createInstance(apiClient);

export const bookAdminCarRental = payload => dispatch => {
  dispatch({ type: SET_IS_BOOKING, isBooking: true });
  return new Promise(async (resolve, reject) => {
    try {
      const bookingData = await service.bookAdmin(payload);
      dispatch({ type: SET_BOOKING_DATA, bookingData });
      dispatch({ type: SET_STEP, step: 2 });
      return resolve(bookingData);
    } catch (e) {
      dispatch({ type: SET_BOOKING_ERROR_MESSAGE, bookingErrorMessage: e.toString() });
      // return reject(e);
    } finally {
      dispatch({ type: SET_IS_BOOKING, isBooking: false });
    }
  });
};
