import React from 'react';
import { withFormsy } from 'formsy-react';
import {
  FormGroup,
  Label,
  Input as ReactStrapInput,
} from 'reactstrap';

class FormInput extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue({ target }) {
    this.props.setValue(target.value);
  }

  render() {
    const { type, onChange, value, title = 'Input Label' } = this.props;
    const { errorMessage } = this.props;

    return (
      <FormGroup>
        <Label>{title}</Label>
        <ReactStrapInput
          type={type}
          value={value}
          onChange={onChange}
          required
        />
        {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
      </FormGroup>
    );
  }
}

export default withFormsy(FormInput);
