import {
  SET_IS_AUTHENTICATING,
  SET_IS_INVALID_CREDENTIALS,
  SET_TOKEN,
  CLEAR_TOKEN,
  SET_USER,
} from '../actions/auth.action';

const initialState = () => ({
  isAuthenticated: false,
  isAuthenticating: false,
  isInvalidCredentials: false,
  token: null,
  user: {
    fullname: null,
    email: null,
    userType: null,
    phoneNumber: null,
  },
  isLoggedOut: false,
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case CLEAR_TOKEN:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        isLoggedOut: true,
        isInvalidCredentials: false,
        user: {
          fullname: null,
          email: null,
          userType: null,
          phoneNumber: null,
        },
      };
    case SET_TOKEN:
      return {
        ...state,
        isAuthenticated: true,
        token: action.token,
      };
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case SET_IS_AUTHENTICATING:
      return {
        ...state,
        isAuthenticating: action.isAuthenticating,
      };
    case SET_IS_INVALID_CREDENTIALS:
      return {
        ...state,
        isInvalidCredentials: action.isInvalidCredentials,
      };

    default:
      return state;
  }
}
